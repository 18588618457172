import {
  BLOG,
  CAREERS,
  PRICING,
  ABOUT_US,
  WHY_HYBR1D,
  APP_MANAGEMENT,
  HYBR1D_PLATFORM,
  DEVICE_MANAGEMENT,
  IDENTITY_MANAGEMENT,
  ADDITIONAL_SERVICES,
  BECOME_SECURITY_COMPLIANT,
  EMPLOYEE_LIFECYCLE_MANAGEMENT,
  FOR_FOUNDERS_HR_AND_IT_ADMINS,
  SAVE_COSTS_AND_ESTABLISH_SCALABLE_PROCESSES,
  INVENTORY_MANAGEMENT,
  PEOPLE_MANAGEMENT,
} from 'utils/getters'

export const navbarLinks: {
  heading: string
  url?: string
  external?: boolean
  dropdown?: {heading?: string; subLinks?: {title: string; url: string; external?: boolean}[]}
}[] = [
  {
    heading: 'Product',
    dropdown: {
      subLinks: [
        {title: 'ZenAdmin Platform', url: HYBR1D_PLATFORM},
        {title: 'Employee Lifecycle Management', url: EMPLOYEE_LIFECYCLE_MANAGEMENT},
        {title: 'Mobile Device Management', url: DEVICE_MANAGEMENT},
        {title: 'Inventory Management', url: INVENTORY_MANAGEMENT},
        {title: 'App Management', url: APP_MANAGEMENT},
        {title: 'Identity Management', url: IDENTITY_MANAGEMENT},
        {title: 'People Management', url: PEOPLE_MANAGEMENT},
        {title: 'IT Helpdesk Support', url: ADDITIONAL_SERVICES}
      ],
    },
  },
  {
    heading: 'Solutions',
    dropdown: {
      subLinks: [
        {title: 'For Founders, HR and IT Admins', url: FOR_FOUNDERS_HR_AND_IT_ADMINS},
        {title: 'Become security compliant', url: BECOME_SECURITY_COMPLIANT},
        {
          title: 'Save costs & establish scalable processes',
          url: SAVE_COSTS_AND_ESTABLISH_SCALABLE_PROCESSES,
        },
      ],
    },
  },
  {
    heading: 'Company',
    dropdown: {
      subLinks: [
        {title: 'About us', url: ABOUT_US},
        {title: 'Careers', url: CAREERS, external: true},
      ],
    },
  },
  // {
  //   heading: 'Pricing',
  //   url: PRICING,
  // },
  {
    heading: 'Why ZenAdmin',
    url: WHY_HYBR1D,
  },
  {
    heading: 'Blog',
    url: BLOG,
  },
]
